import React from 'react'
import useAuth from '../useAuth'
import Route, { ProtectedRouteProps } from './ProtectedRouteBase'

/**
 * Component that checks authentication and authorization against the AuthContext and its current session
 *
 * @component
 * @example
 *    <ProtectedRoute allowed={[MY-AD-GROUP]} path="/protected" exact />
 */
const ProtectedRoute = (props: ProtectedRouteProps) => {
  const auth = useAuth()
  const passThroughProps = !auth.noProvider
    ? { ...props, session: auth.session, onUnauthenticated: auth.login }
    : props
  return <Route {...passThroughProps} />
}

export default ProtectedRoute
