const getEnv = () => {
  if (process.env.REACT_APP_ENV) {
    return process.env.REACT_APP_ENV
  }
  const tapRegex = /.+\.(\w+)\.target\.com/
  if (tapRegex.test(window.location.origin)) {
    const result = tapRegex.exec(window.location.origin)
    if (result && result[1]) {
      return result[1]
    }
  }
  return process.env.NODE_ENV
}

export default getEnv
