import React, { useEffect, ComponentType } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { createURLString } from '../utils/urls'
import Session from '../Session'
import { ACCESS_DENIED } from '../constants'
import { RedirectParam } from '../context'

interface RenderNoAuthProps {
  redirect?: string
  render?: ComponentType
  history?: History
  callback?: RedirectHandler
}

const RenderNoAuth = ({
  redirect = ACCESS_DENIED,
  render: Render,
  callback,
}: RenderNoAuthProps) => {
  useEffect(() => {
    if (callback) callback({ redirect: window.location.href })
    else {
      const validatedRedirect = createURLString(redirect)
      if (validatedRedirect) window.location.replace(validatedRedirect)
    }
  }, [callback, redirect])

  if (Render) {
    return <Render />
  }
  return null
}

/**
 * Callback function that should be used to handle a redirect from ProtectedRoute that could be
 * due to a session being either not authenticated or not authorized for a given path
 *
 * @param redirect - the redirect object that describes the route where access was attempted
 */
export type RedirectHandler = (redirect: RedirectParam) => void

/**
 * Props for ProtectedRoute
 *
 * @extends {RouteProps} react-router-dom
 */
export interface ProtectedRouteProps<
  ADGroups extends string[] = string[],
  Path extends string = string
> extends RouteProps {
  /**
   * an array of AD groups that are allowed to access this `path`
   * @note values must be the common name (CN) value only
   * @note users can access the path if they have *at least* one of the listed allowed AD groups
   * @note providing an empty array will check for authentication only
   * @defaultValue [] (an empty array)
   */
  allowed?: ADGroups
  /** a valid Session instance
   * @see {Session}
   * @defaultValue the current session in AuthContext
   */
  session?: Session | null
  /**
   * callback function that is called when given session is not authenticated
   * @defaultValue current AuthContext login function
   */
  onUnauthenticated?: RedirectHandler
  /** Component to render if given session is not authenticated */
  renderUnauthenticated?: ComponentType
  /**
   * URL to redirect the user when current session is not authenticated
   * @defaultValue https://logonservices.iam.target.com/login/responses/accessdenied.html
   */
  unauthenticatedRoute?: Path
  /** callback function that is called when given session is not authorized */
  onUnauthorized?: RedirectHandler
  /** Component to render if given session is not authenticated */
  renderUnauthorized?: ComponentType
  /**
   * URL to redirect the user when current session is not authorized against given `allowed` prop
   * @defaultValue https://logonservices.iam.target.com/login/responses/accessdenied.html
   */
  unauthorizedRoute?: Path
}

const ProtectedRoute = ({
  path,
  exact,
  strict,
  location,
  sensitive,
  children,
  render,
  ...props
}: ProtectedRouteProps) => {
  const {
    allowed = [],
    component,
    onUnauthenticated,
    onUnauthorized,
    unauthenticatedRoute = ACCESS_DENIED,
    unauthorizedRoute = ACCESS_DENIED,
    renderUnauthenticated,
    renderUnauthorized,
    session,
  } = props
  const authenticated =
    session && session instanceof Session && session.isAuthenticated()
  if (!authenticated) {
    return (
      <RenderNoAuth
        redirect={unauthenticatedRoute}
        callback={onUnauthenticated}
        render={renderUnauthenticated}
      />
    )
  }
  const authorized = authenticated && session!.isAuthorized(allowed)
  if (!authorized) {
    return (
      <RenderNoAuth
        redirect={unauthorizedRoute}
        callback={onUnauthorized}
        render={renderUnauthorized}
      />
    )
  }
  return (
    <Route
      path={path}
      exact={exact}
      strict={strict}
      location={location}
      sensitive={sensitive}
      component={component}
      children={children}
      render={render}
    />
  )
}

export default ProtectedRoute
