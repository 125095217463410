import axios from 'axios'

import { CONFIG_PATH } from './constants'

async function* configGenerator(configPath) {
  let config = null
  const res = await axios.get(configPath)
  if (res.status === 200) {
    config = res.data
  } else {
    throw new Error(`Error retrieving config at ${configPath}`)
  }
  while (true) {
    yield config
  }
}

let generator = null

const getEnvConfig = async (configPath = CONFIG_PATH) => {
  try {
    if (!generator) {
      generator = configGenerator(configPath)
    }
    const config = await generator.next()
    return Promise.resolve(config.value)
  } catch (ex) {
    console.error('Error occurred during getEnvConfig', ex)
    return Promise.reject(ex)
  }
}

export default getEnvConfig
